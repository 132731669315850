.fe_page_default{
  #container{
    margin-top:5rem;

    &::after{
      content:'';
      display:block;
      clear:both;
      float:none;
    }
  }
}

#container{
//  background:$light-gray;
  h1,h2,h3,h4{
    text-transform: uppercase;
  }

  h2,h3,h4{
    font-size:1.25rem;
  }

  .subline{
    font-weight:bolder;
  }

  .mod_breadcrumb {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 1rem;
  }

  #main{
    & >.inside{
      & > .mod_article{
        margin-bottom: 4rem;
        & > .ce_rsce_row-start.grid-x{
          @extend .grid-margin-x;
          + br{
            margin-top:2rem;
          }
        }
        .ce_text{
          p{
            font-size: 1rem !important;
          }
        }
      }
      .breaker{
    clear: both;
    float: none !important;
    margin: -2rem 0 2rem;
      }
    }
  }

  #oeffnungszeiten{
    @extend .callout;
    position:relative;
    background: $secondary-color;
    border:0;
    color:$white;
    padding:0;
    h4{
      padding:1rem .5rem;
    }
    table{
      margin-bottom: 2.5rem;
      border:0;
      tbody{
        border:0;
      }
      tr{
        background:darken($secondary-color,10%);
        border:0;
        &:nth-child(odd){
          background:darken($secondary-color,5%);
        }
      }
    }
    footer{
      background: darken($secondary-color,15%);
      color: #fff;
      bottom: 0;
      width: 100%;
      position: absolute;
      padding: .5rem;

      .fa{
        border-right:1px solid $white;
        padding-right:.5rem;
        margin-right:.5rem;
      }
    }
  }

  .oeffnungszeiten{
    background: $secondary-color;
    color:$white;
  }

  #newsletter-wrapper{
    height:100%;
    background:$primary-color;
    color:$white;
    cursor:pointer;
    transition: background .4s ease;
    &:hover{
      background:darken($primary-color, 10%);
    }

    .fa{
      font-size:3rem;
    }
    #newsletter{
      margin-top:2rem;
      padding-top:2rem;
      border-top:1px solid rgba($white,.25);
    }
  }

  :not(.small-news){
  .more{
    a{
      @extend .button;
      @extend .small;
    }
  }
  }

  .small-news{
    border-bottom:1px solid $light-gray;
    margin-bottom:1rem;
    &:last-child{
      border-bottom:0;
    }
    h3{
      margin-bottom:0;
      & + p{
        margin-top:0;
      }
    }
    .more a{
      background:$white;
      color:$primary-color;
      padding:0;
      &:hover{
        background:$white;
        color:$secondary-color;
      }
    }
  }

  .ce_form{
    .invisible{
      display:none;
    }
    input{
      &[type="submit"]{
        @extend .button;
      }
    }
    .widget-checkbox.data-privacy{
      fieldset span{
        display:flex;
        flex-direction: row;
        align-items: flex-start;
        input{

          display:block;
        }
        label{
          margin-top:-7px;
          display:block;
        }

      }
    }
    .widget-explanation{
      p{
        font-size:80%;
      }
    }
  }
}
