.mod_search{

  .formbody{
    @extend .grid-x;
    label{
      display: none;
    }
    input{
      @extend .cell;
      &[type="search"]{
        @extend .medium-8;
      }
      &[type="submit"]{
        @extend .medium-4;
        @extend .button;
      }
    }

  }

  .header{
    margin-bottom:3rem;
  }

  .even,.odd{
    @extend .callout;
    border:0;
  }

  .even{
    background:$light-gray;
  }

}
