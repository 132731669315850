#teaserbereich{
  figure{
    position:relative;
    &::before{
      content:'';
      display: block;
      opacity: 0;

      position:absolute;
      background:rgba($primary-color,.5);
      height:100%;
      width:100%;
      transition: all .2s ease-in;
    }
    &:hover{
      &::before{
        opacity: 1;
      }
      figcaption{
        p,h3{
          background:0;
          // color:$black;
        }
      }
    }
    figcaption{
      @include vertical-center;
      width:100%;
      text-align: center;
      p,h3{
        background:rgba($primary-color,.75);
        display:inline-block;
        padding-right:.75rem;
        padding-left:.75rem;
        color:$white;
        font-size:1.45rem;

        transition: all .2s ease-in;
      }
    }

  }
}
