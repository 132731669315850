#footer{
  border-bottom:20px solid $secondary-color;

  padding:2rem 0 0 0;
  #seitenfuss{
    @include breakpoint(small only) {
      .columns{
        &:nth-child(1){
          order:2;
        }
        &:nth-child(2){
          order:1;
        }
      }
    }

    @extend .grid-container;
    p{
      padding: 0.7rem 1rem;
      line-height: 1;
    }
    .menu{
      @include breakpoint(small only) {
        justify-content: flex-start;
        font-size:80%;
      }
      .divider{
          width:1px;
          background:rgba($secondary-color,.25);
      }
    }
  }

  .footer-info{
    p{
      font-size:80%;
    }
  }
  .footer-buttons{
    a{
      @extend .button;
      @extend .hollow;
      border-color:$light-gray;
    }
  }
}
