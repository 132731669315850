.mod_newslist{
  h2{
    font-size:1.25rem;
    font-weight: bolder;
  }
  .info{
    font-size:80%;
    color:$secondary-color;
  }
}


.mod_newsreader{
  h1{
    font-size:1.25rem;
    font-weight: bolder;
    color:$primary-color;
  }
  h2{
    font-size:1.25rem;
    font-weight: normal;
  }
  .info{
    font-size:80%;
    color:$secondary-color;
  }

  .back a{
    @extend .button;
    @extend .small;
  }
}
