.main-overview{

  h3{
    font-size:1.25rem;
    font-weight:bolder;
    color:$dark-gray;
    margin-top:1rem;
  }
  p{
    color:$black;
  }

}

#category-overview{

  width:75%;
  float:left;
  padding-bottom:5rem;

  &.full {
    width: 100%;
    clear: both;
    float: none;
  }

  .grid-x{
    @extend .grid-margin-x;
  }

  .large-up-4{
    .ce_text{
      background:$white;
      &.highlight{


      }
          &:hover{
            background:$white;

          }
          position:relative;
        height:450px;

      .teaser{
        padding:0;
        border-top:1px dotted $primary-color;
        padding-top:.5rem;
        h2{
          font-size:1rem;
          text-transform:none;
          font-weight:bolder;
          margin-bottom:0;
        }
        p{
          color:$black;
          margin:0;
          strong{
            font-weight:normal;
            font-size:80%;
          }
        }
        .small.button{
          position:absolute;
          bottom:0;
          width:100%;
        }

      }

    }
  }

  .ce_text{
    margin-bottom:1rem;
    // background:$light-gray;
    transition:all .3s ease;

    &:hover{
      background:rgba($primary-color,.2);

    }
    figure{
      figcaption{
        margin-top:.5rem;
        // padding:.5rem;
        h3{
          font-weight:bolder;
          margin-bottom:0;
          transition: all .4s ease;

        }
        p{
          color:$black;
          transition: all .4s ease;
        }
      }
    }
    .teaser{
      padding:.5rem;
    }
  }

  + #sidebar{
    width:20%;
    float:right;
    h1{
      font-size:1.25rem;
      font-weight:bolder;
      color:$medium-gray;
    }

    .mod_newscategories{
      > .accordion-menu{
        a{
          padding-left:0;
        }
        li{
          border-bottom:1px solid $light-gray;
          padding-top:.25rem;
          padding-bottom:.25rem;
          padding-left:1rem;
          &:last-child{
            border:0;
          }
        }
        .submenu{
          li{
            padding-left:.5rem;
          }
        }
      }
      .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after{
        right:auto;
        left:-1rem;
      }
    }
  }

}
