#header{

  .is-dropdown-submenu{
    border:0;
  }

  #logo{
    span:nth-child(1){
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 3.5rem;
      margin-bottom: -10px;
      display: block;
      color:$primary-color;

    }
    span:nth-child(2){
      display:block;
      font-size: 1.25rem;
      text-transform: uppercase;
      color:darken($primary-color,10%);

    }
  }

  #seitenkopf-suche{
    background:$primary-color;
    margin-bottom:1rem;
    @include breakpoint(medium) {
      margin-bottom:3rem;
    }

    @include breakpoint(small only) {
      > .grid-container{
        padding:0;
      }
    }

    .dropdown.menu{
      .is-dropdown-submenu-parent{
        background:darken($primary-color,10%);
        > a{
          color:$white;
          &::after{
            border-color:$white transparent transparent;
          }
        }
        > .submenu{
          border:0;
          background-color:darken($primary-color,10%);
          > li{
            border-bottom:1px solid rgba($white,.25);
            &:last-child{
              border:0;
            }
          }
          a{
            color:$white;
          }
        }
      }
    }
      input{
        margin-bottom:0;
        &[type="text"]{
          background: darken($primary-color,10%);
          box-shadow: none;
          border:0;

          &:focus{
            background:$primary-color;
            color:$white;
          }
        }
        &[type="submit"]{
          background: darken($primary-color,15%);
          color:$white;
          width:100%;
        }
      }
  }

  #meta-container{
    margin:1rem auto 0;

    .menu{
      @include breakpoint(small only) {
        background:$light-gray;
        justify-content: flex-start;
      }
    }
  }

  .title-bar{
    background:$secondary-color;
  }

  #responsive-menu{
    padding:0;

    .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after{
      border-color: $white transparent transparent;
    }
    .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after{
      border-color: transparent transparent transparent $secondary-color;
    }

    > div > ul > li{
      border-right:1px solid $white;//rgba($primary-color,.25);
      &:last-child{
        border:0;
      }
      a{
        ::after{

        }
      }
      ul{
        background-color:$white;
        li{
          border-bottom:1px solid rgba($secondary-color, .2);
        }
        a{
          color:$secondary-color;
        }
      }
    }
    a{
      text-transform: uppercase;
      transition:all .4s ease;
      color:$white;
      font-size:1rem;
      padding-top:1rem;
      padding-bottom:1rem;
      &:hover{
        background:darken($secondary-color,10%);
        color:$white;
      }
    }
  }


  #teaserbereich{
    @extend .grid-container;

    margin:0 auto 3rem auto;
    > br{
      display:none;
    }
    $abstand_teaser : .5rem;
    // > .ce_text{
    //   margin:$abstand_teaser 0;
    // }

    > .grid-x{
      // margin-bottom:$abstand_teaser;
      &:first-child{
        margin-top:$abstand_teaser;
      }
      .ce_text{
        padding-right:$abstand_teaser;
        &:last-child{
          padding-right:0;
        }
      }
    }

    .teaserblock{
      position:relative;
      height:300px;
      .teaser-content{
        @include vertical-center;

        h2,p{
          background:rgba($dark-gray,.7);
          margin-bottom:0;
          display:inline;
          padding:.25rem;
          line-height: 1.6;
          text-transform:uppercase;
          color:$white;
          &::after{
            content:"\A";
            white-space:pre;
          }
          &:last-child{
            &::after{display:none;}
          }
        }
        h2{
          font-size:1.25rem;
          background:rgba($primary-color,.7);
          &::after{
          }
        }
      }
    }
    .medium-12 .teaserblock{
      height:500px;
      background-size:cover;
      background-repeat:no-repeat;
      background-position: center center;
    }

  }

}
